import React from "react"
import Layout from "../../components/member/Layout"
import Group from "../../components/member/Group"

export default () => {
  const pageSlug = "group"
  const pageTitle = "グループ"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Group />
    </Layout>
  )
}
