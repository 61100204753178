import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "1rem 0 3rem",
  },
  lead: {
    marginBottom: "2rem",
  },
  link: {
    "&:hover": {
      "& .MuiPaper-root": {
        filter: "brightness(90%)",
      },
    },
  },
  paper: {
    transition: "filter .3s",
    padding: "1.5rem",
    minHeight: "360px",
    [theme.breakpoints.up("md")]: {
      minHeight: "420px",
    },
    "& .gatsby-image-wrapper": {
      minHeight: "150px",
      maxHeight: "200px",
      [theme.breakpoints.up("md")]: {
        minHeight: "260px",
      },
    },
  },
  heading: {
    fontSize: "1.1rem",
    marginTop: "1rem",
  },
  desription: {
    color: theme.palette.grey[700],
    fontSize: "0.75rem",
    marginTop: "0.8rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.85rem",
    },
  },
}))

export default () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      imageFood: file(relativePath: { eq: "member/group/img_food.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFishing: file(relativePath: { eq: "member/group/img_fishing.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageBBQ: file(relativePath: { eq: "member/group/img_bbq.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageAR: file(relativePath: { eq: "member/group/img_ar.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">
          グループ
        </Box>
      </Typography>

      <Typography className={classes.lead}>
        箕澤屋メンバーの趣味趣向で集まる勝手なテーマ別グループ。
      </Typography>

      <Grid container spacing="4">
        <Grid item xs={12} sm={6} lg={4}>
          <Link to="#" className={classes.link}>
            <Paper className={classes.paper}>
              <Img fluid={data.imageFood.childImageSharp.fluid} alt="" />
              <Typography variant="h3" className={classes.heading}>
                食に関すること何でも研究部
              </Typography>
              <Typography className={classes.desription}>
                箕澤屋カフェで提供できるものをつくることを目標に、さまざまな食材やレシピを自由に研究中。
              </Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Link to="#" className={classes.link}>
            <Paper className={classes.paper}>
              <Img fluid={data.imageFishing.childImageSharp.fluid} alt="" />
              <Typography variant="h3" className={classes.heading}>
                釣り部
              </Typography>
              <Typography className={classes.desription}>
                2021年に箕澤屋釣りツアー開催を目指して、スポットや釣り情報などを収集するコーナー。
              </Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Link to="#" className={classes.link}>
            <Paper className={classes.paper}>
              <Img fluid={data.imageBBQ.childImageSharp.fluid} alt="" />
              <Typography variant="h3" className={classes.heading}>
                BBQを楽しむ会
              </Typography>
              <Typography className={classes.desription}>
                春〜秋にかけて企画したいBBQをより楽しむためのBBQ研究する会。
              </Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Link to="#" className={classes.link}>
            <Paper className={classes.paper}>
              <Img fluid={data.imageAR.childImageSharp.fluid} alt="" />
              <Typography variant="h3" className={classes.heading}>
                箕澤屋AR
              </Typography>
              <Typography className={classes.desription}>
                箕澤屋の囲炉裏に火を灯したいとの案で生まれたAR体験グループ。
              </Typography>
            </Paper>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}
